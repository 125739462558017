import { useRouteError } from "react-router-dom";
import ToMenuButton from "../components/ToMenuButton"
import LogoComponent from "../components/LogoComponent";

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>

      <div className="bg-orange-400 w-screen min-h-screen grid grid-cols-5">
        <div className="p-8 col-start-2 col-span-3">
          <LogoComponent />
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
      <ToMenuButton />
    </>
  )
}

export default ErrorPage;

