
import { XCircleIcon } from '@heroicons/react/20/solid';
import cross from '../assets/cross.png'
import { useRemoveArrayElement, useFirebaseStorageDelete } from '../services/firebaseFunctions';
import { useState } from 'react';


const PhotoDeleteComponent = ({ props }) => {
    const [{ deleted, error }, deleteFile] = useFirebaseStorageDelete();
    const [update, setUpdate] = useState(false);
    const { removeElement } = useRemoveArrayElement();


    const handleClick = () => {
        deleteFile(props.imageObj.url);
        removeElement(props.id, 'images', props.imageObj)
    }

    return (
        <div className="my-4 mb-8" onClick={handleClick}>
            <li key={props.key} >
                <img src={props.imageObj.url} alt={props.imageObj.bijschrift} />
                <p className="my-4 text-1xl">{props.imageObj.bijschrift}</p>
                <img src={cross} alt="delete hier" className='w-24' />
            </li>
        </div>
    )
}

export default PhotoDeleteComponent