import LogoComponent from "../components/LogoComponent";
import ToMenuButton from "../components/ToMenuButton"

function VideoPage() {
    return (

        <div className="bg-orange-400 bg-center bg-no-repeat w-screen min-h-screen grid grid-cols-5">

            <div className="col-start-2 col-span-3">
                <LogoComponent />
                <video autoplay="" class="vjs-tech" dataSetup="{&quot;controlBar&quot;:{&quot;volumeMenuButton&quot;:false,&quot;durationDisplay&quot;:false}}" id="video_html5_api" muted="muted" poster="https://cdn-webcam-harlingen.nl/loading/loading-eernewoude.jpg" preload="none" tabindex="-1" src="blob:https://webcam-friesemeren.nl/b61ecdfd-0cd4-46b6-ab29-13970b27b356">
                </video>
            </div>
            <ToMenuButton />
        </div>


    )
}

export default VideoPage




