

import flag_img from '../assets/flag.png'
import no_flag from '../assets/no_flag.png'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import db from '../services/firebase';
import { useGetHuisjeFromUserID } from '../services/firebaseFunctions'
import { doc, updateDoc } from "firebase/firestore";
import Alert from './Alert';

function VlagCard() {

    const [{ id, data, loading, error }] = useGetHuisjeFromUserID(null);
    const [flag, setFlag] = useState(!data.vlagGehesen);

    //console.log(data.vlagGehesen)


    useEffect(() => {
        setFlag(data.vlagGehesen)
    }, [id])

    const onFlagChange = () => {
        if (Object.keys(id).length > 0) {
            var docRef = doc(db, 'huisjes', id)
            updateDoc(docRef, { vlagGehesen: !flag })
            setFlag(!flag)
        } else {
            console.log("User of huisje niet gevonden")
        }
    }

    function checkFlagImg() {
        if (flag) {
            return (
                <img
                    className="w-12"
                    src={flag_img}
                    alt="vlag is gehesen"
                />
            )
        } else {
            return (
                <img
                    className="w-12"
                    src={no_flag}
                    alt="vlag is gestreken"
                />
            )
        }
    }

    function checkFlagTxt() {
        if (flag) {
            return "Strijk de vlag"
        } else {
            return "Hijs de vlag!"
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const text = <p>Dit is de {data.aantal_keer_ingelogd}e keer dat u bent inlogd. U kan in <i>mijn gegevens</i> aangeven welke gegevens u wil delen. U kan dan ook de vlag hijsen voor uw huisje en het prikbord gebruiken!</p>

    return (
        <div>
            {data.shareDataAllowed ? (
                < div onClick={() => onFlagChange()}>
                    <Link to="/map">
                        <div className="grid grid-cols-12">
                            <div className="p-2 m-2 rounded-lg col-span-8">
                                <h1 className="text-2xl">
                                    {checkFlagTxt()}
                                </h1>
                            </div>


                            <div className="col-start-9 w-32 m-2">
                                {checkFlagImg()}
                            </div>
                        </div >
                        <hr />
                    </Link>
                </div >
            ) : (
                <div>
                    {(data.aantal_keer_ingelogd < 2) ? (
                        <Alert text={text} hidden={false} />

                    ) : (
                        <div>
                        </div>
                    )}
                </div>
            )}
        </div>

    )
}

export default VlagCard