import { useState } from "react";

const Accordion = ({ controllerElement, children }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    return (
        <div >
            <div className="bg-orange-400 bg-opacity-80 p-2 m-1 rounded-lg w-full text-2xl"
                onClick={() => setIsExpanded(prevIsExpanded => !prevIsExpanded)}
            >
                {controllerElement(isExpanded)}
            </div>
            {isExpanded && <div className="ml-6">{children}</div>}
            <hr />
        </div>

    )

}

export default Accordion