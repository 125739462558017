import React from 'react';
import { signOut } from "firebase/auth";
import { auth } from '../services/firebase';
import { useNavigate } from 'react-router-dom';
import ToMenuButton from "../components/ToMenuButton"
import LogoComponent from '../components/LogoComponent';
import Button from '../components/Button';

function SignOutPage() {
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    return (


        <div className="bg-orange-400 w-screen min-h-screen grid grid-cols-5">
            <div className="p-8 col-start-2 col-span-3">
                <LogoComponent />
                <div className="flex justify-center">
                    <Button onClick={handleLogout} text={"Uitloggen"} />

                </div>
            </div>
            <ToMenuButton />
        </div>

    )
}

export default SignOutPage