import { useGetCollectionFromFirestore } from "../services/firebaseFunctions";
import SmoelenboekCard from "../components/SmoelenboekCard";
import ToMenuButton from "../components/ToMenuButton"
import LogoComponent from "../components/LogoComponent";
import PhotoComponent from "../components/PhotoComponent";


function SmoelenboekDetailPage() {
    const [{ data, loading, error }] = useGetCollectionFromFirestore('huisjes');
    const id = decodeURI(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
    const huisje = data.filter((r) => r.naam_huisje == id)[0]

    return (

        <div className="bg-orange-400 w-screen min-h-screen grid grid-cols-5">
            <div className="p-8 col-start-2 col-span-3">
                <LogoComponent />

                {error && <div>Something went wrong ...</div>}
                {loading ? (
                    <div>
                        Even wachten...
                    </div>
                ) : (
                    <div>
                        <ul>
                            <SmoelenboekCard props={huisje} />
                        </ul>

                        <br />
                        {!huisje.shareDataAllowed ? (
                            <div></div>
                        ) : (
                            <div>
                                <p className="m-2 text-orange-100">Een beschrijving...</p>
                                <div className="col-start-2 col-span-3 bg-orange-400 rounded-lg p-2">
                                    {huisje.description}
                                </div>
                                <br />
                                <p className="m-2 text-orange-100">De bewoners...</p>
                                <div className="col-start-2 col-span-3 bg-orange-400 rounded-lg p-2">
                                    {huisje.namen}
                                </div>
                                <br />
                                {huisje.images ? (
                                    <ul>
                                        {huisje.images.map((imageObj, index) => (
                                            <PhotoComponent props={imageObj} key={index} />
                                        ))}
                                    </ul>
                                ) : (
                                    <div></div>
                                )}

                            </div>

                        )}



                    </div>
                )}

            </div>
            <ToMenuButton />
        </div>


    )

}

export default SmoelenboekDetailPage