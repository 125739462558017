import React from 'react';

import { useNavigate } from 'react-router-dom';

function InPopupCard(props) {

    const navigate = useNavigate();

    const handleClick = (id) => {
        navigate("/smoelenboek/" + id)
    }

    return (
        <div>
            <h3 className="text-xl">{props.naam_huisje}</h3>

            <img
                className="rounded-md"
                src={props.imageLink}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "./images/foto-ontbreekt.jpeg";
                }}
                width={120}
                height={80}
                alt={'De foto van ' + props.naam_huisje + ' ontbreekt helaas.'}
            />
            <br />
            <div>
                {props.shareDataAllowed ? (
                    <div>
                        {props.familie}<br />
                    </div>
                ) : (
                    <div>
                        <p className="text-xs">De bewoners hebben<br />geen gegevens gedeeld</p>
                    </div>
                )

                }

            </div>


            <p className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => handleClick(props.naam_huisje)}>meer</p>

        </div>
    )
}

export default InPopupCard;