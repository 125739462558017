import { useState, useEffect } from 'react';
import db from '../services/firebase'; // Import your Firebase configuration
import { collection, getDocs } from 'firebase/firestore';

export const useDataFromFirebase = (collectionName) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const querySnapshot = await getDocs(collection(db, collectionName));
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() });
                });
                setData(items);
            }
            catch (err) {
                setError(err)
            }
            finally {
                setLoading(false)
            }

        };

        fetchData();
    }, [collectionName]);

    return { data, error, loading };
};