

function WeerHourCard({ props }) {

    return (
        <div className="grid grid-cols-8 gap-x-1">
            <div className="col-start-1 col-span-2">{props.uur.substr(props.uur.lastIndexOf(' ') + 1)}</div>
            <div className="col-start-3 col-span-1"><img className="mx-2" src={`./images/iconen-weerlive/${props.image}.png`} alt={props.image} /></div>
            <div className="col-start-4 col-span-1">{props.temp}°C</div>
            <div className="col-start-5 col-span-4">wind: {props.windbft} {props.windr}</div>
        </div >
    )
}

export default WeerHourCard;