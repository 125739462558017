import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './App.css';
import MapComponent from './components/MapComponent';
import MenuPage from "./pages/MenuPage";
import SmoelenboekPage from "./pages/SmoelenboekPage";
import SmoelenboekDetailPage from "./pages/SmoelenboekDetailPage"
import EvenementenPage from "./pages/EvenementPage";
import BestuurPage from "./pages/BestuurPage";
import ErrorPage from './pages/ErrorPage';
import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";
import MijnGegevens from "./pages/MijnGegevens";
import SignOutPage from "./pages/SignOutPage";
import WeerPage from "./pages/WeerPage";
import VideoPage from "./pages/VideoPage";
import PrikbordPage from "./pages/PrikbordPage";
import PhotoUploadPage from "./pages/PhotoUploadPage";
import PhotoDeletePage from "./pages/PhotoDeletePage";
import ToevoegenPage from "./pages/ToevoegenPage";

import db from './services/firebase'
import { collection, getDocs } from 'firebase/firestore';



async function loaderHuisjes() {
  const huisjesRef = collection(db, 'huisjes')
  const snapshot = await getDocs(huisjesRef)
  snapshot.forEach(doc => {
    //console.log(doc.id, '=>', doc.data());
  });
  return snapshot.docs;
}

// Loader function to check authentication
const requireAuth = async () => {
  const auth = getAuth();
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(null); // User is signed in, proceed to route
      } else {
        resolve(redirect('/login')); // User not signed in, redirect to login
      }
    });
  });
};

const router = createBrowserRouter([
  { path: "/", element: <LoginPage />, errorElement: <ErrorPage /> },
  { path: "signup", element: <SignupPage /> },
  { path: "login", element: <LoginPage /> },
  { path: "menu", element: <MenuPage />, onEnter: requireAuth, loader: loaderHuisjes, loader: requireAuth },
  { path: "mijngegevens", element: <MijnGegevens />, loader: requireAuth },
  { path: "map", element: <MapComponent />, loader: loaderHuisjes, loader: requireAuth },
  { path: "smoelenboek/:id", element: <SmoelenboekDetailPage />, loader: requireAuth },
  { path: "bestuur", element: <BestuurPage />, loader: requireAuth },
  { path: "smoelenboek", element: <SmoelenboekPage />, loader: loaderHuisjes, loader: requireAuth },
  { path: "evenementen", element: <EvenementenPage />, loader: requireAuth },
  { path: "signout", element: <SignOutPage />, loader: requireAuth },
  { path: "weer", element: <WeerPage />, loader: requireAuth },
  { path: "video", element: <VideoPage />, loader: requireAuth },
  { path: "prikbord", element: <PrikbordPage />, loader: requireAuth },
  { path: "upload", element: <PhotoUploadPage />, loader: requireAuth },
  { path: "delete", element: <PhotoDeletePage />, loader: requireAuth },
  { path: "toevoegen", element: <ToevoegenPage />, loader: loaderHuisjes },
]);

function App() {
  //useUser()
  return (
    <RouterProvider router={router} />
  );
}

export default App;

/**

      <Routes>
        <Route path="/" element={<MapComponent />} />
        <Route path="/map" element={<MapComponent />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/menu/bestuur" element={<BestuurPage />} />
        <Route path="/menu/smoelenboek" element={<SmoelenboekPage />} />
        <Route path="/menu/evenementen" element={<EvenementenPage />} />
      </Routes>

*/