import LogoComponent from "../components/LogoComponent"
import ToMenuButton from "../components/ToMenuButton"
import PhotoDeleteComponent from "../components/PhotoDeleteComponent";
import { useGetHuisjeFromUserID } from "../services/firebaseFunctions";

const PhotoDeletePage = () => {
    const [{ id, data }] = useGetHuisjeFromUserID();

    return (


        <div className="bg-orange-400 bg-center bg-no-repeat w-screen min-h-screen grid grid-cols-5" >
            <div className="p-8 col-start-2 col-span-3 bg-opacity-75">
                <LogoComponent />
                {data.images ? (
                    <ul >
                        {data.images.map((imageObj, index) => (
                            <PhotoDeleteComponent props={{
                                'imageObj': imageObj,
                                'id': id,
                                'key': index
                            }} key={index} />
                        ))}
                    </ul>
                ) : (
                    <div></div>
                )}
            </div>
            <ToMenuButton />
        </div>


    )
}

export default PhotoDeletePage