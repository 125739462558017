import React, { useState, useEffect } from 'react';
import { CloudArrowUpIcon } from '@heroicons/react/24/solid'
import { useFirebaseStorage, useGetHuisjeFromUserID, useUpdateDocInFirestore } from '../services/firebaseFunctions';
import { arrayUnion } from "firebase/firestore";
import Button from './Button'
import Alert from './Alert';



const FileUploadComponent = () => {
    const [file, setFile] = useState(null);
    const [bijschrift, setBijschrift] = useState(' ');
    const { uploadFile, url, error } = useFirebaseStorage();
    const [previewImage, setPreviewImage] = useState(null);
    const [{ id }] = useGetHuisjeFromUserID();
    const [{ data, loading }, setCollectionName, setDocID, setData] = useUpdateDocInFirestore()

    useEffect(() => {
        setCollectionName('huisjes')
        setDocID(id)
        setData({
            images: arrayUnion({
                "url": url,
                "bijschrift": bijschrift
            })
        })
    }, [url])

    function handleUpload() {
        if (!file) {
            alert('Kies eerst een foto om op te laden')
            return
        }
        uploadFile('photos', file, bijschrift)

    }

    // Function to prevent default behavior for drag over
    const handleDragOver = (event) => {
        event.preventDefault()
    }

    // Function to handle image drop
    const handleDrop = (event) => {
        event.preventDefault()
        const uploadFile = event.dataTransfer.files[0]
        if (uploadFile) {
            setPreviewImage(URL.createObjectURL(uploadFile))
            setFile(uploadFile)
        }
    }

    //Create the openFileInput function to trigger a click on the hidden file input when the container is clicked.
    const openFileInput = () => {
        document.getElementById("hiddenFileInput").click()
    }

    const handleChange = (e) => {
        e.preventDefault()
        const uploadFile = e.target.files[0]
        if (uploadFile) {
            setPreviewImage(URL.createObjectURL(uploadFile))
            setFile(uploadFile)
        }
    }

    return (
        <div >
            <Alert hidden={!error} text="Er is iets missgegaan bij het opladen"/>
            <div onDragOver={handleDragOver} onDrop={handleDrop} onClick={openFileInput} className=" m-2 p-2 rounded outline outline-offset-0 outline-4 outline-dashed outline-orange-500 place-items-center bg-orange-300 h-80 flex justify-center">
                {previewImage ?
                    <img src={previewImage} className="object-contain w-80 h-80" />
                    :
                    <div>
                        <CloudArrowUpIcon className="w-60 h-20 " />
                        <br />
                        <h1 className="flex justify-center">Drop hier een foto</h1>
                    </div>
                }
                <input
                    type="file"
                    style={{ display: 'none' }}
                    id="hiddenFileInput"
                    onChange={handleChange}
                />

            </div>
            <div>
                <h2 className="m-2 text-orange-100">Tik hier een bijschrift (optioneel)..</h2>
                <textarea
                    className="w-full h-10 mb-4 p-2 px-5 rounded"
                    id="beschrijving"
                    name="beschrijving"
                    type="text"
                    //defaultValue={data.description ? data.description : " "}
                    required
                    placeholder="Bijschrift bij de foto..."
                    onChange={(e) => setBijschrift(e.target.value)}
                />
            </div>
            <Button onClick={handleUpload} text="Afbeelding opslaan" />
        </div>
    );
};


export default FileUploadComponent;
