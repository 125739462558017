import LogoComponent from '../components/LogoComponent'
import ToMenuButton from "../components/ToMenuButton"
import WeerHourCard from '../components/WeerHourCard';
import WeerDayCard from '../components/WeerDayCard';
import { useGetWeatherFromKMNI } from '../services/weerService';


function WeerPage() {
    const [{ data, loading, error }] = useGetWeatherFromKMNI();

    if (!loading) {
        console.log(data)
    }

    return (


        <div className="bg-orange-400 w-screen min-h-screen grid grid-cols-5">
            <div className="p-8 col-start-2 col-span-3 cursor-not-allowed">
                <LogoComponent />
                {data ? (
                    <div>
                        <div>
                            {data.liveweer[0].verw}.<br />
                            {data.liveweer[0].ltekst}
                            <hr className="mb-4 mt-4" />
                        </div>
                        <div className="grid grid-cols-2">
                            <div>
                                <p className="my-2 text-orange-100">Komende uren...</p>
                                <br />
                                <ul>
                                    {data.uur_verw.map((uur) => (
                                        < WeerHourCard props={uur}
                                            key={uur.uur} />
                                    )
                                    )}
                                </ul>
                            </div>
                            <div>
                                <p className="my-2 text-orange-100">Komende dagen...</p>
                                <br />
                                <ul>
                                    {data.wk_verw.map((dag) => (
                                        < WeerDayCard props={dag}
                                            key={dag.dag} />
                                    )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>
                        <i>Heen en weer...</i>
                    </p>
                )

                }


            </div>
            <ToMenuButton />
        </div>
    )
}

export default WeerPage