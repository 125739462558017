import { useGetHuisjeFromUserID } from '../services/firebaseFunctions';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, getDoc, addDoc, updateDoc, query, where, onSnapshot } from 'firebase/firestore';
import db from '../services/firebase';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

export const addLog = async (userID) => {
    try {
        const userHuisje = query(collection(db, 'huisjes'), where('users', 'array-contains', userID));
        const querySnapshot = await getDocs(userHuisje);
        querySnapshot.forEach((doc) => {
            updateHuisje(doc.id, doc.data().aantal_keer_ingelogd);
        })

    } catch {
        console.log('error')
    }

}

async function updateHuisje(id, aantalKerenIngelogd) {
    const huisjeObj = doc(db, "huisjes", id)
    await updateDoc(huisjeObj, { aantal_keer_ingelogd: aantalKerenIngelogd + 1 }).then(
        console.log('succes')
    )
};

