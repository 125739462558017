import { useDeleteDocumentFromFirestore } from '../services/firebaseFunctions';
import { XCircleIcon } from '@heroicons/react/20/solid'
import cross from '../assets/cross.png'


const ItemDeleteButton = ({ props }) => {
    const [{ loading, error }, setDocID] = useDeleteDocumentFromFirestore('prikbordItems')

    const handleClick = () => {
        console.log('delete', props.id)
        setDocID(props.id)
        //delete props.id
    }

    return (
        <div className='float-right'>
            <img src={cross} onClick={handleClick} className='h-8 w-8' />
        </div>
    )
}
export default ItemDeleteButton