
import flag from '../assets/flag.png'
import no_flag from '../assets/no_flag.png'
import { useNavigate } from "react-router-dom";

function EvenementCard({ props }) {
    const navigate = useNavigate();

    const handleClick = (id) => {
        navigate("/evenementen/" + id)
    }

    return (
        <>
            <li key={props.id} onClick={() => {/*handleClick(props.id)*/}}>
                <div className="flex m-1">
                    <div className="flex-none w-1/6 m-2">
                        <img
                            className="rounded-md"
                            src={props.imageLink}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "../images/foto-ontbreekt.jpeg";
                            }}
                            alt={'De foto van ' + props.naam + ' ontbreekt helaas.'}
                        />
                    </div>
                    <div className="flex-initial w-64 m-2">
                        <h2 className="text-xl m-2">{props.naam}</h2>
                        <h3 className="text-sm m-2">Datum: {props.datum}</h3>
                    </div>
                    <div className="flex-initial w-64 m-2">
                        {props.omschrijving}
                    </div>
                </div>
            </li>
            <hr />
        </>

    )
}

export default EvenementCard