import { useAddDocumentToFirestore, useGetHuisjeFromUserID } from '../services/firebaseFunctions'
import { useState } from "react";
import Button from "../components/Button";

function NewPrikbordItem() {
    const [{ documentRef }, setData, setCollectionName] = useAddDocumentToFirestore();
    const [{ id, data, loading, error }] = useGetHuisjeFromUserID();
    const [prikbordItem, setPrikbordItem] = useState(' ')
    const today = new Date();

    const handleClick = () => {
        setCollectionName('prikbordItems')
        setData({
            "item": prikbordItem,
            "afzender": data.naam_huisje,
            "datum": today
        });
        setPrikbordItem('')
    }

    return (
        <div className="mb-4">
            <div>
                <h2 className="m-2 text-orange-100">Tik hier een nieuw berichtje..</h2>
                <textarea
                    className="w-full h-40 mb-4 p-2 px-5 rounded"
                    id="beschrijving"
                    name="beschrijving"
                    type="text"
                    //defaultValue={data.description ? data.description : " "}
                    required
                    placeholder="Bericht voor op het prikbord."
                    onChange={(e) => setPrikbordItem(e.target.value)}
                />
            </div>
            <Button onClick={handleClick} text={"Nieuw bericht aanmaken..."} />
        </div>
    )
}

export default NewPrikbordItem