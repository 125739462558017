import React, { useEffect, useState } from 'react';
import ToMenuButton from "../components/ToMenuButton"
import LogoComponent from '../components/LogoComponent';
import Switch from '../components/Switch';
import SmoelenboekCard from '../components/SmoelenboekCard';
import Button from '../components/Button';
import { useGetHuisjeFromUserID, useGetUserUID, useUpdateDocInFirestore } from '../services/firebaseFunctions';

function MijnGegevens() {
    const [{ user, userID }] = useGetUserUID(' ');
    const [{ id, data, loading, error }] = useGetHuisjeFromUserID(userID);
    const [description, setDescription] = useState(' ');
    const [familie, setFamilie] = useState(' ');
    const [share, setShare] = useState(false);
    const [namen, setNamen] = useState(' ');
    const [{ }, setCollectionName, setDocID, setData] = useUpdateDocInFirestore();



    useEffect(() => {
        setFamilie(data.familie)
        setShare(data.shareDataAllowed)
        setDescription(data.description ? data.description : ' ')
        setNamen(data.namen ? data.namen : ' ')
    }, [id])



    const handleClick = () => {
        console.log({
            "shareDataAllowed": share,
            "familie": familie,
            "description": description,
            "namen": namen
        })
        setCollectionName('huisjes')
        setDocID(id)
        setData({
            "shareDataAllowed": share,
            "familie": familie,
            "description": description,
            "namen": namen
        })

    }

    const handleSwitch = (e) => {
        setShare(e.target.checked)
    }

    return (
        <div className="bg-orange-400 w-1/1 min-h-screen grid grid-cols-5">

            <div className='col-start-2 col-span-3'>
                <div className="p-8">
                    <LogoComponent />
                    <ul>
                        <SmoelenboekCard props={data} />
                    </ul>

                    <form >
                        <div className="w-full p-2 m-2 rounded-lg">
                            <div id="toggleAnonyous" className="grid grid-cols-3 gap-4 place-content-center">
                                <div className={`w-1/1 p-2 m-2 rounded-lg col-span-1 ${!share ? 'bg-orange-200 outline-dotted' : 'outline-dotted'}`}>
                                    <div className={"p-1 px-5 rounded"}>
                                        <p> Wij blijven graag anoniem</p>
                                    </div>
                                </div>
                                <div className="grid place-content-center">
                                    <Switch checked={share} onChange={(e) => handleSwitch(e)} />
                                </div>

                                <div className={`w-1/1 p-2 m-2 rounded-lg col-span-1 ${share ? 'outline-dotted bg-orange-200' : 'outline-dotted'}`}>
                                    <div className={"p-1 px-5 rounded indent-0"}>
                                        Wij vinden het leuk om wat fotos en tekst te delen met andere leden van de Princenhof.
                                    </div>
                                </div>
                            </div>
                            {share &&
                                <div id="additionalForm" >
                                    <hr className="mt-4 mb-4" />
                                    <h2 className='text-orange-200'>Naam van de familie</h2>
                                    <input
                                        className="w-full p-2 mb-4 px-5 rounded"
                                        id="familieNaam"
                                        name="familieNaam"
                                        type="text"
                                        defaultValue={data.familie}
                                        required
                                        placeholder="Naam van de familie"
                                        onChange={(e) => setFamilie(e.target.value)}
                                    />
                                    <h2 className='text-orange-200'>Wat meer informatie, leuk om te delen in het smoelenboek..</h2>
                                    <textarea
                                        className="w-full h-40 mb-4 p-2 px-5 rounded"
                                        id="beschrijving"
                                        name="beschrijving"
                                        type="text"
                                        defaultValue={data.description ? data.description : " "}
                                        required
                                        placeholder="Beschrijving"
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <h2 className='text-orange-200'>Namen en contactgegevens van de bewoners..</h2>
                                    <textarea
                                        className="w-full h-40 mb-2 p-2 px-5 rounded"
                                        id="name,"
                                        name="namen"
                                        type="text"
                                        defaultValue={data.namen ? data.namen : " "}
                                        required
                                        placeholder="Namen van de familieleden en telefoonnummers"
                                        onChange={(e) => setNamen(e.target.value)}
                                    />


                                </div>
                            }

                        </div>
                    </form>

                    <div className='grid place-content-center'>
                        <Button onClick={handleClick} text={'Opslaan'} />
                    </div>

                </div>
            </div >
            <ToMenuButton />
        </div>
    )
}

export default MijnGegevens


/**
 *                                     
 */