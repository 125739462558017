import React from "react";
import { Outlet, Link } from "react-router-dom";
import img from "../assets/hamburger.png"

function ToMenuButton() {
    return (
        <div className="mt-28 ml-12">
            <Link to="/menu">
                <img
                    src={img}
                    width={40}
                    height={40}
                    alt='Naar het menu'
                />
            </Link>
        </div>
    )
}

export default ToMenuButton