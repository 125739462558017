function Button({ onClick, text }) {
    return (
        <div>
            <button onClick={onClick} className="bg-ph-purple hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                {text}

            </button>
        </div>
    )
}

export default Button;