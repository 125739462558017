import FileUploadComponent from "../components/FileUploadComponent"
import LogoComponent from "../components/LogoComponent"
import ToMenuButton from "../components/ToMenuButton"

function PhotoUploadPage() {
    return (

        <div className="bg-orange-400 bg-center bg-no-repeat w-screen min-h-screen grid grid-cols-5" >
            <div className="p-8 col-start-2 col-span-3 bg-opacity-75">
                <LogoComponent />
                <FileUploadComponent />
            </div>
            <ToMenuButton />
        </div>

    )
}
export default PhotoUploadPage