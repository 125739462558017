// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { updateDoc, getDoc, doc } from "firebase/firestore";
import { getStorage } from 'firebase/storage';



import { getFirestore, collection, getDocs } from 'firebase/firestore';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCAHe5g4gU5SrZg2UWt8ir6Qur8t4crAKU",
    authDomain: "aldefeanen-b8efd.firebaseapp.com",
    projectId: "aldefeanen-b8efd",
    storageBucket: "aldefeanen-b8efd.appspot.com",
    messagingSenderId: "385365653475",
    appId: "1:385365653475:web:7b578df9166b33dee952d6",
    measurementId: "G-EKE2XSMLTG",
    databaseURL: "https://aldefeanen-b8efd-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { storage, db as default, auth };

export const updateHuisjeFromUser = (userID, huisjeID, huisje) => {
    const huisjeDocRef = collection(db, 'huisjes', huisjeID)
    huisje.lastUpdatedBy = userID
    return updateDoc(huisjeDocRef, huisje)
}

export function getCurrentUserID() {
    if (auth.currentUser) {
        return auth.currentUser.uid;
    }
    return null
}


export const getHuisjeIDFromCurrentUser = async (userID) => {
    const querySnapshot = await getDocs(collection(db, "huisjes"));
    const items = [];
    let huisjeID = ""

    querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
    });
    items.find(huisje => {
        if (huisje.users) {
            let result = huisje.users.find(item => item === userID)
            if (result) {
                huisjeID = huisje.id
            }
        }
    })
    return huisjeID
}

export const getHuisjeData = async (huisjeID) => {
    const huisjeDocRef = doc(db, 'huisjes', huisjeID)
    const huisjeData = await getDoc(huisjeDocRef)
    return huisjeData.data()
}