/**
Je gebruikersnaam is: clickglue
Je API - key is: c58d385c11

Na het activeren van je account kunt je de weergegevens van bijvoorbeeld Amsterdam als volgt opvragen:
http://weerlive.nl/api/json-data-10min.php?key=c58d385c11&locatie=Amsterdam of 
https://weerlive.nl/api/weerlive_api_v2.php?key=demo&locatie=52.0910879,5.1124231

De complete gebruiksinstructies van de API vind je op Weerlive.nl

Eernewoude :53.129539142191945, 5.934188945461526
*/

import { useState, useEffect } from 'react';

export const useGetWeatherFromKMNI = () => {
    const [data, setData] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        setError(false);
        const fetchData = async () => {
            try {
                const queryData = await fetch('https://weerlive.nl/api/weerlive_api_v2.php?key=c58d385c11&locatie=53.129539142191945, 5.934188945461526')
                const weer = await queryData.json();
                setData(weer)
                setLoading(false);
            }
            catch (error) {
                console.log(error)
                setError(true);
            }
        }
        fetchData()

    }, [])

    return [{ data, loading, error }]
}