import React from "react";
import { Marker, Popup, Tooltip, SVGOverlay } from 'react-leaflet';
import L, { divIcon } from 'leaflet';
import InPopupCard from './InPopupCard';


var pin = new L.Icon({
    iconUrl: require('../assets/base.png'),
    iconSize: [18, 12], // size of the icon
    iconAnchor: [8, 0], // point of the icon which will correspond to marker's location
    popupAnchor: [4, -50] // point from which the popup should open relative to the iconAnchor
})

var flag = new L.Icon({
    iconUrl: require('../assets/vlag_dun.png'),
    shadowUrl: require('../assets/flag_shadow.png'),
    iconSize: [40, 64], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [16, 58], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 80],  // the same for the shadow
    popupAnchor: [4, -50] // point from which the popup should open relative to the iconAnchor
})

var no_flag = new L.Icon({
    iconUrl: require('../assets/mast_dun.png'),
    shadowUrl: require('../assets/no_flag_shadow.png'),
    iconSize: [20, 64], // size of the icon
    shadowSize: [35, 64], // size of the shadow
    iconAnchor: [16, 58], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [4, -50] // point from which the popup should open relative to the iconAnchor
})

function FlagMarker({ huisje }) {

    return (
        <div>

        <SVGOverlay 
        bounds={[[huisje.position[0]-0.005, huisje.position[1]-0.005],[huisje.position[0]+0.005, huisje.position[1]+0.005]]}>
        <text x="50%" y="50%" stroke="black">
          {huisje.nummer}
        </text>
        </SVGOverlay>
        <Marker
            position={[huisje.position[0], huisje.position[1]]}
            icon={
                !huisje.shareDataAllowed ? pin : (
                    huisje.vlagGehesen === true ? flag : no_flag)}
            key={huisje.naam_huisje}
        >
            <Popup children={InPopupCard(huisje)}>
            </Popup>
            <Tooltip>{huisje.nummer}: {huisje.naam_huisje}</Tooltip>
        </Marker>

      </div>
        )
};

export default FlagMarker

