import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../services/firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import LogoComponent from '../components/LogoComponent';
import { useGetHuisjeFromUserID } from '../services/firebaseFunctions';
import { addLog } from '../services/addLog';
import Alert from '../components/Alert';

const LoginPage = () => {
    const [{ id, data, loading, error }] = useGetHuisjeFromUserID();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError,setLoginError]=useState(false);

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user)
                addLog(user.uid);
                navigate("/map")
            })
            .catch((error) => {
                console.log(error.code, error.message)
                setLoginError(true)
            });

    }

    return (
        <>
            <div className="bg-orange-400 w-screen min-h-screen grid grid-cols-5">
                <div className="p-8 place-content-center col-start-2 col-span-3">
                    <LogoComponent />
                    <form className="flex justify-center">
                        <div>
                        <Alert
                                hidden={!loginError}
                                text={"Uw emailadres en/of uw paswoord worden niet herkend. Kan u het nog eens proberen? Als het niet lukt, neem dan contact op met het bestuur."} />
                            <div className="p-2 m-2 rounded-lg">

                                <input
                                    className="w-80 p-2 px-5 rounded-full"
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Email address"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setLoginError(false)
                                    }
                                    }
                                />
                            </div>

                            <div className="p-2 m-2 rounded-lg">

                                <input
                                    className="w-80 p-2 px-5 rounded-full"
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    placeholder="Password"
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                        setLoginError(false)
                                    }}
                                />
                            </div>

                            <div className="p-2 m-2 rounded-lg">
                                <button
                                    className="w-80 p-2 px-5 rounded-full bg-orange-200"
                                    onClick={onLogin}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </form>

                    


                    <div className="text-sm text-white text-center">
                        Nog niet ingeschreven? {' '}
                        <NavLink to="/signup">
                            <p className="underline underline-offset-4">Registreer</p>

                        </NavLink>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LoginPage