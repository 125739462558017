import { Link } from "react-router-dom";


function MenuCard(props) {
    return (
        <>
            <Link to={props.route}>
                <div className="bg-orange-400 bg-opacity-80 p-2 m-1 rounded-lg w-full">
                    <h1 className=" text-2xl">
                        {props.element}
                    </h1>
                </div>
            </Link>
            <hr />
        </>
    )
}

export default MenuCard