import db from './firebase.js';
import { storage } from './firebase.js';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, getDoc, addDoc, updateDoc, query, where, onSnapshot, deleteDoc, arrayRemove } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";


export const useGetCollectionFromFirestore = (propsCollectionName) => {
    const [data, setData] = useState([]);
    const [collectionName, setCollectionName] = useState(propsCollectionName);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(false);
            try {
                const q = query(collection(db, collectionName))
                onSnapshot(q, (querySnapshot) => {
                    let items = []
                    querySnapshot.forEach((doc) => {
                        items.push({ id: doc.id, ...doc.data() })
                    })
                    setData(items)
                    setLoading(false);
                })
            } catch (error) {
                console.log(error)
                setError(true)
            }

        }
        fetchData();
    }, [collectionName])
    return [{ data, loading, error }, setCollectionName]
}

//const [{ data, loading, error }, setCollectionName]=useGetCollectionFromFirestore();

export const useGetDocumentFromFirestore = () => {
    const [data, setData] = useState();
    const [docID, setDocID] = useState();
    const [collectionName, setCollectionName] = useState()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true);
        setError(false);
        const fetchData = async () => {
            try {
                const queryData = await getDoc(doc(db, collectionName, docID));
                setData(queryData.data())
                setLoading(false);
            }
            catch (error) {
                console.log(error)
                setError(true);
            }
        }
        fetchData()

    }, [docID, collectionName])
    return [{ data, loading, error }, setDocID, setCollectionName]
}

export const useAddDocumentToFirestore = () => {
    const [data, setData] = useState();
    const [documentRef, setDocumentRef] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [collectionName, setCollectionName] = useState();

    useEffect(() => {
        setLoading(true)
        setError(false)
        const addDocument = async () => {
            try {
                const docRef = await addDoc(collection(db, collectionName), { data })
                setDocumentRef(docRef);
            } catch (error) {
                console.log(error)
                setError(true)
            }
            setLoading(false)
        }
        addDocument();
    }, [data, collectionName])

    return [{ documentRef, loading, error }, setData, setCollectionName]
}

/**
 * In the component, import this function and use it like this, linked to an onClick event:
 *  const [{ documentRef, loading, error }, setData, setCollectionName] = useAddDocumentToFirestore();
    const handleClick = (collectionName, data) => {
        setCollectionName(collectionName)
        setData(data);
    }
    <button onClick={() => {
        handleClick('collection-to-be-written-to', data-to-be-written)
    }
 */


export const useDeleteDocumentFromFirestore = (collection) => {
    const [collectionName, setCollectionName] = useState(collection);
    const [docID, setDocID] = useState(' ')
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true)
        setError(false)
        const deleteDocument = async () => {
            try {
                await deleteDoc(doc(db, collectionName, docID))
            } catch (error) {
                console.log(error)
                setError(true)
            }
            setLoading(false)
        }
        deleteDocument();
    }, [docID, collectionName])

    return [{ loading, error }, setDocID, setCollectionName]
}

/**
 const [{ loading, error },setDocID, setCollectionName]=useDeleteDocumentFromFirestore('')
 */


export const useUpdateDocInFirestore = () => {
    const [data, setData] = useState()
    const [collectionName, setCollectionName] = useState();
    const [docID, setDocID] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        const updateData = async () => {
            setLoading(true)
            setError(false)
            try {
                const docRef = doc(db, collectionName, docID)
                await updateDoc(docRef, data)
                alert("De gegevens zijn opgeslagen!");
            }
            catch (error) {
                setError(true)
                console.log(error)
            }
            setLoading(false)
        }
        updateData();
    }, [collectionName, docID, data])
    return [{ data, error, loading }, setCollectionName, setDocID, setData]
}
/** 
const [{ data, error, loading }, setCollectionName, setDocID, setData] = useUpdateDocInFirestore()

const handleClick = () => {
    setData({ vlagGehesen: input });
    setDocID('VLToFMhmYh5ynCHAOlyb')
    setCollectionName('huisjes')
}
*/

export const useRemoveArrayElement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const removeElement = async (documentId, arrayField, elementValue) => {
        setIsLoading(true);
        setError(null);

        try {
            const docRef = doc(db, 'huisjes', documentId); // Replace 'yourCollectionName' with your actual collection name
            await updateDoc(docRef, {
                [arrayField]: arrayRemove(elementValue),
            });
            setIsLoading(false);
        } catch (err) {
            setError(err);
            console.log(error)
            setIsLoading(false);
        }
    };

    return { removeElement, isLoading, error };
};



export const useGetUserUID = () => {
    const [userID, setUserID] = useState('empty-user');
    const [user, setUser] = useState(false)
    useEffect(() => {
        const getUID = () => {
            try {
                const auth = getAuth();
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        setUserID(user.uid)
                        setUser(true)
                    } else {
                        setUser(false)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
        getUID();
    }, [])
    return [{ user, userID }]
}

/**
const [{ user, userID }] = useGetUserUID();
 */

export const useGetHuisjeFromUserID = () => {
    const [data, setData] = useState('');
    const [id, setID] = useState('');
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [userIDToLookup, setUserIDToLookup] = useState('empty-user');

    useEffect(() => {
        const getHuisje = async () => {
            setError(false)
            setLoading(true)
            try {
                const auth = getAuth();
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        setUserIDToLookup(user.uid)
                    } else {
                        console.log("user not found")
                    }
                })
                const userHuisje = query(collection(db, 'huisjes'), where('users', 'array-contains', userIDToLookup));
                const querySnapshot = await getDocs(userHuisje);
                querySnapshot.forEach((doc) => {
                    setData(doc.data());
                    setID(doc.id)
                })
                setLoading(false)
            } catch (error) {
                setError(true)
                console.log(error)
            }
        }
        getHuisje();
    }, [userIDToLookup])
    return [{ id, data, loading, error }]
}
/**
const [{ id, data, loading, error }] = useGetHuisjeFromUserID(); 
*/

export const useTriggerRerender = (propCollectionName) => {
    const [updated, setUpdated] = useState();
    const [collectionName, setCollectionName] = useState(' ');

    useEffect(() => {
        setCollectionName(propCollectionName)
    }, [propCollectionName])

    useEffect(() => {
        const q = query(collection(db, collectionName))
        onSnapshot(q, (querySnapshot) => {
            setUpdated(true)
        })
    }, [collectionName])

    useEffect(() => {
        //console.log('rerenderd')
        setUpdated(false)
    }, [updated])
    return [(updated)]
}

//const [flagChanged] = useTriggerRerender('huisjes');


export const useFirebaseStorage = () => {
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);

    const uploadFile = (path, file, bijschrift) => {
        const storageRef = ref(storage, `${path}/${file.name}`);
        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded blob or file', snapshot);
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                setUrl(downloadURL)
            })

        }).catch((error) => {
            console.log('Upload failed: ', error)
            setError(error)
        })
    }
    return { uploadFile, url, error };
};

// const [{ uploadFile, url, error }]=useFirebaseStorage();

export const useFirebaseStorageDelete = () => {
    const [error, setError] = useState(null);
    const [deleted, setDeleted] = useState(null);

    const deleteFile = (fileUrl) => {
        const fileRef = ref(storage, fileUrl)
        // Delete the file
        deleteObject(fileRef).then(() => {
            setDeleted(true)
            // File deleted successfully
        }).catch((error) => {
            setError(true)
            // Uh-oh, an error occurred!
        });
    }
    return [{ deleted, error }, deleteFile];
};

// const [{ deleted, error }, deleteFile]=useFirebaseStorageDelete();
