
import LogoComponent from '../components/LogoComponent'
import Accordion from '../components/Accordion'
import MenuCard from "../components/MenuCard"
import VlagCard from "../components/VlagCard"
import rietkraag from '../assets/rietkraag.svg'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

function MenuPage() {
    return (
        <div>
            <div className="bg-orange-400 bg-center bg-no-repeat w-screen min-h-screen grid grid-cols-5" style={{
                backgroundImage: `url(${rietkraag})`
            }}>

                <div className="p-8 col-start-2 col-span-3 bg-opacity-75">

                    <LogoComponent />

                    <VlagCard />

                    <MenuCard element="Wie is in Earnewâld?" route="/map" />
                    <MenuCard element="Prikbord" route="/prikbord" />

                    <div>
                        <Accordion
                            controllerElement={(isExpanded) => (
                                <div className='grid grid-cols-12'>
                                    <p className='col-span-8'>Meer over Vereniging De Princenhof</p>
                                    {isExpanded ? <ChevronUpIcon className="col-start-9 col-span-1 w-8" /> : <ChevronDownIcon className="col-start-9 col-span-1 w-8" />}
                                </div>
                            )}>
                            <ul>
                                <MenuCard element="smoelenboek" route="/smoelenboek" />

                                <MenuCard element="evenementen" route="/evenementen" />
                                <MenuCard element="bestuur" route="/bestuur" />
                            </ul>
                        </Accordion>
                    </div>
                    <div>
                        <Accordion
                            controllerElement={(isExpanded) => (
                                <div className='grid grid-cols-12'>
                                    <p className='col-span-8'>Meer over Earnewâld</p>
                                    {isExpanded ? <ChevronUpIcon className="col-start-9 col-span-1 w-8" /> : <ChevronDownIcon className="col-start-9 col-span- w-8" />}
                                </div>
                            )}>
                            <ul>
                                <MenuCard element="het weer in Earnewâld" route="/weer" />
                                <MenuCard element="de webcam van de princenhof" route="/video" />
                            </ul>
                        </Accordion>
                    </div>
                    <div>
                        <Accordion
                            controllerElement={(isExpanded) => (
                                <div className='grid grid-cols-12'>
                                    <p className='col-span-8'>Meer over Mijn profiel</p>
                                    {isExpanded ? <ChevronUpIcon className="col-start-9 col-span-1 w-8" /> : <ChevronDownIcon className="col-start-9 col-span-1 w-8" />}
                                </div>
                            )}>
                            <ul>
                                <MenuCard element="mijn gegevens" route="/mijngegevens" />
                                <MenuCard element="foto's opladen" route="/upload" />
                                <MenuCard element="foto's verwijderen" route="/delete" />
                                <MenuCard element="uitloggen" route="/signout" />
                                {/*<MenuCard element="toevoegen" route="/toevoegen" />*/}
                            </ul>
                        </Accordion>
                    </div>
                </div>
            </div >

        </div>
    )
}

export default MenuPage