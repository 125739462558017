import ToMenuButton from "../components/ToMenuButton"
import LogoComponent from "../components/LogoComponent";
import { useDataFromFirebase } from "../hooks/useDataFromFirebase";


function BestuurPage() {
    const { data, loading, error } = useDataFromFirebase("evenementen");
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>

            <div className="bg-orange-400 w-screen min-h-screen grid grid-cols-5">

                <div className="p-8 col-start-2 col-span-3">
                    <LogoComponent />
                    <div>Dit is het bestuur:</div>
                    <div>Bart van Nunen, Voorzitter</div>
                    <div>Sabine Doets - Van den Akker, Secretaris</div>
                    <div>Henk Sybrandy, Penningmeester</div>
                    <div>Aan te vullen...</div>
                </div>
                <ToMenuButton className='col-start-5' />
            </div>

        </>
    )
}

export default BestuurPage