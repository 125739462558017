import { Timestamp } from "firebase/firestore";
import { useAddDocumentToFirestore, useGetHuisjeFromUserID } from '../services/firebaseFunctions'
import ItemDeleteButton from "./ItemDeleteButton";


function PrikbordItemCard({ props }) {
    const datum = props.data.datum.toDate();
    const [{ id, data, loading, error }] = useGetHuisjeFromUserID();

    return (

        <div className="bg-ph-light m-2 p-2 rounded-xl rotate-">
            <p className="text-xs m-2">
                van: {props.data.afzender}
                <br />
                datum: {datum.toLocaleString()}
            </p>
            <hr />
            <div>
                {props.data.item}
            </div>
            {props.data.afzender === data.naam_huisje ? (
                <div>
                    <ItemDeleteButton props={props} />
                </div>
            ) : (
                <div></div>
            )}
        </div>


    )
}

export default PrikbordItemCard;