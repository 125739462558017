import ToMenuButton from "../components/ToMenuButton"
import EvenementCard from "../components/EvenementCard"
import LogoComponent from "../components/LogoComponent";
import { useGetCollectionFromFirestore } from '../services/firebaseFunctions'


function EvenementenPage() {
    //const evenementen = useLoaderData();
    const [{ data, loading, error }] = useGetCollectionFromFirestore('evenementen');


    return (
        <>

            <div className=" bg-orange-400 w-screen min-h-screen grid grid-cols-5">
                <div className="bg-orange-400 col-start-2 col-span-3">
                    <div className="p-8 justify-center">
                        <LogoComponent />
                        <nav>
                            {data.length ? (
                                <ul>
                                    {data.map((evenement) => (
                                        <EvenementCard props={evenement}
                                            key={evenement.id} />
                                    ))}
                                </ul>
                            ) : (
                                <p>
                                    <i>Geen evenementen gevonden</i>
                                </p>
                            )}
                        </nav>
                    </div>
                </div>
                <ToMenuButton />
            </div>

        </>
    )
}

export default EvenementenPage