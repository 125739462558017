const PhotoComponent = ({ props }, key) => {
    return (
        <div className="my-4 mb-8">
            <li key={key}>
                <img src={props.url} />
                <p className="my-4 text-1xl">{props.bijschrift}</p>
            </li>
        </div>
    )
}

export default PhotoComponent