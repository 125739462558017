
import flag from '../assets/flag.png'
import pin from '../assets/base.png'
import no_flag from '../assets/no_flag.png'
import { useNavigate } from "react-router-dom";

function SmoelenboekCard({ props }) {
    const navigate = useNavigate();
    const id = props.naam_huisje

    function checkFlag(props) {
        if (!props.shareDataAllowed) {
            return (
                <img
                    className="w-4 h-4"
                    src={pin}
                    alt="anoniem"
                />
            )
        }

        if (props.vlagGehesen) {
            return (
                <img
                    className="w-12"
                    src={flag}
                    alt="vlag gehesen"
                />
            )
        } else {
            return (
                <img
                    className="w-12"
                    src={no_flag}
                    alt="vlag gestreken"
                />
            )
        }
    }

    const handleClick = () => {
        if (props.shareDataAllowed) {
            navigate("/smoelenboek/" + id)
        } else {
            alert('De bewoners van dit huisje hebben nog geen gegevens verstrekt of blijven liever anoniem')
        }

    }

    return (
        <>
            <li key={props.id} onClick={() => handleClick()}>
                <div className="flex m-1">
                    <div className="flex-none w-1/6 m-2">
                        <img
                            className="rounded-md"
                            src={props.imageLink}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "../images/foto-ontbreekt.jpeg";
                            }}
                            alt={'De foto van ' + props.naam_huisje + ' ontbreekt helaas.'}
                        />
                    </div>
                    <div className="flex-initial w-64 m-2">
                        <h2 className="text-xl m-2">{props.naam_huisje}</h2>
                        <h2 className="text-sm m-2">Nummer op de kaart: {props.nummer}</h2>
                        {props.shareDataAllowed ? (
                            <div>
                                <h3 className="text-sm m-2">{props.familie}</h3>
                            </div>
                        ) : (
                            <div>
                                <p className="text-xs m-2"></p>
                            </div>
                        )}

                    </div>
                    <div className="flex-initial w-64 m-2">
                        {checkFlag(props)}
                    </div>
                </div>
            </li>
            <hr />
        </>

    )
}

export default SmoelenboekCard