// src/components/MapComponent.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import FlagMarker from './FlagMarker';
import ToMapButton from './ToMapButton';
import { useGetCollectionFromFirestore, useTriggerRerender } from '../services/firebaseFunctions'
import { render } from '@testing-library/react';
import { divIcon } from 'leaflet';

const MapComponent = (props) => {
    const [upDate, setUpdate] = useState(false);
    const [{ data, loading, error }, setCollectionName] = useGetCollectionFromFirestore('huisjes');
    const [updated] = useTriggerRerender('huisjes');



    return (
        <div>

            <MapContainer center={[53.12705, 5.92283]} zoom={15} style={{ height: "800px", width: "100%" }} zoomControl={false}>
                <ToMapButton />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {data.map(huisje => {
                    return (
                        <FlagMarker huisje={huisje} key={huisje.id} />
                    )
                })}

            </MapContainer>

        </div>
    );
};

export default MapComponent;


