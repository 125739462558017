import huisjes from "../assets/huisjes_4";
import { collection, doc, setDoc } from "firebase/firestore";
import db from "../services/firebase";

function toevoegen() {
    const huisjesRef = collection(db, 'huisjes')
    huisjes.map((huisje => {
        console.log(huisje)
        setDoc(doc(huisjesRef), huisje)
    }
    ))
}
const ToevoegenPage=()=>{




    return (
<div className="px-8 py-2 place-content-center">
<button
    className="w-80 px-8 py-2 rounded-full bg-orange-200"
    type="submit"
    onClick={toevoegen}
>
    Toevoegen
</button>
</div>
    )
}


export default ToevoegenPage