import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import db from '../services/firebase';
import { auth } from '../services/firebase';
import LogoComponent from '../components/LogoComponent';
import { useGetCollectionFromFirestore } from '../services/firebaseFunctions'
import Alert from '../components/Alert';

const SignupPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [huisjeID, setHuisjeID] = useState('');
    const [huisje, setHuisje] = useState('');
    const [error, setError] = useState(false);
    const [pwError, setPwError] = useState(false);
    const [errorMessage, setErrorMessage]=useState('');
    //"auth/email-already-in-use", "auth/weak-password"
    const [{ data, loading }, setCollectionName] = useGetCollectionFromFirestore('huisjes');

    /** 
    useEffect(() => {
        setCollectionName('huisjes')
    }, [huisjeID])
    */
    function testCode(e) {
        e.preventDefault()
        setError(false)
        try {
            data.filter(function (doc) {
                if (doc.id === huisjeID) {
                    setHuisje(doc)
                }
            })
        } catch (error) {
            console.log(data)
            console.log(error)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const userID = userCredential.user.uid
                console.log(userID)
                async function updateHuisje() {
                    const huisjeObj = doc(db, "huisjes", huisjeID)
                    await updateDoc(huisjeObj, { users: arrayUnion(userID), aantal_keer_ingelogd: 1 }).then(
                        console.log('succes')
                    )
                };
                updateHuisje();
                // Signed in
                navigate("/")
            })
            .catch((error) => {
                console.log(error.code);
                setPwError(true)
                setErrorMessage(error.code)
            });
    }

    return (
        <>
            <main >
                <section>
                    <div className="bg-orange-400 flex place-content-center w-screen min-h-screen">
                        <div className="p-8 place-content-center">
                            <LogoComponent />
                            <Alert
                                hidden={!error || huisje}
                                text="De code is niet juist. Probeer het nog eens." />
                            <Alert
                                hidden={!pwError}
                                text={errorMessage} />
                            <Alert
                                hidden={huisje || error}
                                text="De Alde Feanen App is een toepassing voor een beperkte groep gebruikers. Je kan je alleen maar aanmelden als je de code van een huisje of een woonboot hebt. Die kan je krijgen bij het bestuur van De Princenhof." />
                            <form>
                                <div className="px-8 py-2 place-content-center">
                                    <input
                                        className="w-80 p-2 px-5 rounded-full"
                                        type="text"
                                        label="Voer de code van het huisje in"
                                        value={huisjeID}
                                        onChange={(e) => {
                                            setHuisjeID(e.target.value)
                                        }}
                                        required
                                        placeholder="Code van het huisje"
                                    />
                                </div>
                                <div className="px-8 py-2 place-content-center">
                                    <p className="pt-2 text-center text-lg">{huisje.naam_huisje}</p>
                                </div>
                                <div className="px-8 py-2 place-content-center">
                                    <button
                                        hidden={huisje}
                                        className="w-80 px-8 py-2 rounded-full bg-orange-200"
                                        type="submit"
                                        onClick={testCode}
                                    >
                                        Controleer de code
                                    </button>
                                </div>
                                <div className="px-8 py-2 place-content-center">
                                    <input
                                        disabled={!huisje}
                                        className="w-80 p-2 px-5 rounded-full"
                                        type="email"
                                        label="Email address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder="Email address"
                                    />
                                </div>
                                <div className="px-8 py-2 place-content-center">
                                    <input
                                        disabled={!huisje}
                                        className="w-80 p-2 px-5 rounded-full"
                                        type="password"
                                        label="Create password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        placeholder="Password"
                                    />
                                </div>

                                <div className="px-8 py-2 place-content-center">
                                    <button
                                        hidden={!huisje}
                                        className="w-80 px-8 py-2 rounded-full bg-orange-200"
                                        type="submit"
                                        onClick={onSubmit}
                                    >
                                        Sign up
                                    </button>
                                </div>
                            </form>

                            <div className="pt-8 text-sm text-white text-center">
                                Already have an account?<br></br>
                                <NavLink to="/login" >
                                    <p className="underline underline-offset-4">Sign in</p>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}


export default SignupPage