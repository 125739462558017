import { forwardRef } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';


const Switch = forwardRef((props, forwardedRef) => {
    return (
        <label className="cursor-pointer">
            <input type="checkbox" className="hidden" ref={forwardedRef} {...props} />
            <div className={`w-20 h-8 p-0.5 rounded-full bg-orange-200
                
                `}>
                <div className={`
                    w-fit p-0.5 shadow-sm rounded-full
                    transition-all duration-300 text-white
                    ${props.checked
                        ? "bg-blue-500 translate-x-12 rotate-180"
                        : "bg-blue-500  rotate-0"
                    }
                    `}>
                    {props.checked ? <ArrowLeftIcon className='h-6' /> : <ArrowLeftIcon className='h-6' />}
                </div>

            </div>
        </label>
    )
}

)

export default Switch