


function WeerDayCard({ props }) {
    const image = props.image

    return (
        <div className="grid grid-cols-8">
            <div className="col-start-1 col-span-2 text-xs">{props.dag}</div>
            <div className="col-start-1 col-span-1"><img className="mx-2" src={`./images/iconen-weerlive/${props.image}.png`} alt={props.image} /></div>
            <div className="col-start-3 col-span-1 text-xs">max: {props.max_temp}°C</div>
            <div className="col-start-4 col-span-1 text-xs">min: {props.min_temp}°C</div>
            <div className="col-start-5 col-span-3 text-xs">wind: {props.windbft} {props.windr}</div>

        </div>
    )
}

export default WeerDayCard;