

import ToMenuButton from "../components/ToMenuButton"
import SmoelenboekCard from "../components/SmoelenboekCard"
import LogoComponent from "../components/LogoComponent";
import { useGetCollectionFromFirestore } from "../services/firebaseFunctions";


function SmoelenboekPage() {

    const [{ data, loading, error }, setCollectionName] = useGetCollectionFromFirestore('huisjes')

    return (
        <div className="bg-orange-400 w-screen min-h-screen grid grid-cols-5">

            <div className="bg-orange-400 col-start-2 col-span-3">
                <div className="p-8 justify-center">
                    <LogoComponent />
                    <nav>
                        {data ? (
                            <ul>
                                {data
                                .sort( (a, b) => a.naam_huisje.localeCompare(b.naam_huisje) )
                                .map((huisje) => (
                                    < SmoelenboekCard props={huisje}
                                        key={huisje.id} />
                                )
                                )}
                            </ul>
                        ) : (
                            <p>
                                <i>Geen huisjes gevonden</i>
                            </p>
                        )}
                    </nav>
                </div>
            </div>
            <ToMenuButton />
        </div>
    )
}

export default SmoelenboekPage

/**                                              
{huisje.huisje_naam || contact.last ? (
    <>
        {contact.first} {contact.last}
    </>
) : (
    <i>No Name</i>
)}{" "}
{contact.favorite && <span>★</span>}

*/