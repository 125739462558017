import logo from '../assets/princenhof_vereniging.png'

function LogoComponent() {
    return (
        <div>
            <div className="flex justify-center">
                <img
                    src={logo}
                    className="p-2 m-2 rounded-lg h-32"
                    alt="logo"
                />
            </div>
            <hr className="mb-4" />
        </div>
    )
}

export default LogoComponent