
import LogoComponent from "../components/LogoComponent";
import PrikbordItemCard from "../components/PrikbordItemCard";
import NewPrikbordItem from "../components/NewPrikbordItem";
import ToMenuButton from "../components/ToMenuButton";

import { useGetCollectionFromFirestore, useAddDocumentToFirestore, useGetHuisjeFromUserID, useGetUserUID } from '../services/firebaseFunctions'


function PrikbordPage() {
    const [{ data, loading, error }] = useGetCollectionFromFirestore('prikbordItems');

    const sortedData = data.sort((a, b) => {
        return b.data.datum - a.data.datum
    })

    return (



        <div className="bg-orange-400 bg-center bg-no-repeat w-screen min-h-screen grid grid-cols-5" >
            <div className="p-8 col-start-2 col-span-3 bg-opacity-75">
                <LogoComponent />
                {error &&
                    <div>Something went wrong</div>
                }
                {loading ? (
                    <div>
                        Even wachten....
                    </div>
                ) : (
                    <div>
                        <NewPrikbordItem />
                        <hr />
                        <div className="outline mt-4">
                            <ul>
                                <div className="grid grid-flow-row-dense grid-cols-3 grid-rows-3">
                                    {sortedData.map((item) => (
                                        < PrikbordItemCard props={item}
                                            key={item.id} />
                                    )
                                    )}

                                </div>
                            </ul>
                        </div>


                    </div>
                )}


            </div>
            <ToMenuButton />
        </div >


    )
}
export default PrikbordPage