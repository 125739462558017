//import React from "react";
import { Outlet, Link } from "react-router-dom";
import img from "../assets/hamburger.png"

function ToMapButton() {
    return (
        <div className="leaflet-pane leaflet-overlay-pane top-10 left-10 fixed">
            <Link to="/menu">
                <img
                    src={img}
                    width={60}
                    height={60}
                    alt='Naar de map'
                />
            </Link>
        </div>
    )
}

export default ToMapButton