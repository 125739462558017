let huisjes=
[

    {
        "nummer":"31",
        "shareDataAllowed":false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12629, 5.93277
        ],
        "vlagGehesen": false,
        "naam_huisje": "Ulekrite",
        "familie": "",
        "imageLink": "placeholder.jpg",
    },
    {
        "nummer": "32",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12653, 5.93378
        ],
        "vlagGehesen": false,
        "naam_huisje": "De Pôlle",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "33",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12709, 5.93248
        ],
        "vlagGehesen": false,
        "naam_huisje": "Margriet",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "34",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12671, 5.93172
        ],
        "vlagGehesen": false,
        "naam_huisje": "Ulepôlle",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "34",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.11687, 5.90581 //nr 1 op
        ],
        "vlagGehesen": false,
        "naam_huisje": "Wildlânshiem",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "35",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12562, 5.93013
        ],
        "vlagGehesen": false,
        "naam_huisje": "To Plak",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "36",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12531, 5.92962
        ],
        "vlagGehesen": false,
        "naam_huisje": "Simmerwente",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "37",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12530, 5.92804
        ],
        "vlagGehesen": false,
        "naam_huisje": "Ruimzicht",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "38",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12357, 5.92808
        ],
        "vlagGehesen": false,
        "naam_huisje": "Arendswoud",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "39",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12755, 5.92532
        ],
        "vlagGehesen": false,
        "naam_huisje": "Titania",
        "familie": "",
        "imageLink": "placeholder.jpg"
    },
    {
        "nummer": "40",
        "shareDataAllowed": false,
        "login": [
            "",
            ""
        ],
        "position": [
            53.12880, 5.92723
        ],
        "vlagGehesen": false,
        "naam_huisje": "Kiekeboe",
        "familie": "",
        "imageLink": "placeholder.jpg"
    }
     
]

export default huisjes